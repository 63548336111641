@use 'sass:map';
@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();
mat.$theme-ignore-duplication-warnings:disabled;

// Define the light theme.
$primary: mat.m2-define-palette(mat.$m2-light-blue-palette);
$accent:  mat.m2-define-palette(mat.$m2-green-palette, A200, A100, A400);
$warn: mat.m2-define-palette(mat.$m2-red-palette);

$jt-light-theme: mat.m2-define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn
  ),
  typography: mat.m2-define-typography-config(),
));

.jt-light-theme {
  --mat-toolbar-container-background-color: #dcdcdc;

  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.all-component-themes(map.merge($jt-light-theme, (
    typography: mat.m2-define-typography-config()
  )));
}
